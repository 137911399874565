<template>
  <div id="map" style="height: 260px; width: 100%; margin:auto"></div>
</template>

<script>
import L from 'leaflet';

export default {
  mounted() {
    setTimeout(() => {
      this.map = L.map('map').setView([-12.051554436016898, -77.03612554977931], 16);
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors',
        maxZoom: 20,
      }).addTo(this.map);

      const myIcon = L.icon({
      // eslint-disable-next-line global-require
        iconUrl: require('../../../assets/marker.png'),
        iconSize: [18, 18],
      });

      const markers = [
        { latitud: -12.049115360755, longitud: -77.03819773345721, nombre: 'Crillon' },
        { latitud: -12.05235856072034, longitud: -77.03401809892199, nombre: 'Fénix' },
        { latitud: -12.052517920790494, longitud: -77.03429079032996, nombre: 'Sudameris' },
      ];

      markers.forEach((markerData) => {
        const marker = L.marker([markerData.latitud, markerData.longitud], { icon: myIcon })
          .addTo(this.map);
        marker.bindPopup(markerData.nombre);
      });
    }, 2000);
  },
};
</script>

<style>
#map {
  height: 100%;
  width: 100%;
}
</style>
