<template>
  <div>
    <v-dialog v-model="mapCercadoDialog"  width="450px">
      <v-card class="pb-6 overflow-hidden overflow-y-hidden">
        <div class="text-right">
          <v-icon @click="mapCercadoDialog = false" color="teal"
          class="px-5 py-5">mdi-close</v-icon>
        </div>
        <v-card-text>
          <component :is="mapComponent"></component>
          <p class="pl-5 pt-3 mb-0 font-weight-bold blue--text text--darken-4"
            v-for="(element, index) in directionsForMap" :key="index">
              {{ element.nameSede }}:
              <span class="font-weight-medium black--text">{{ element.direction }}</span>
            </p>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import CercadoMap from './cercado_map.vue';

export default {
  name: 'description_service_modal',
  props: {
    value: Boolean,
  },
  components: {
    CercadoMap,
  },
  data() {
    return {
      mapComponent: 'CercadoMap',
      directionsForMap: [{ nameSede: 'Crillón', direction: 'Av. Nicolás de Piérola #589' }, { nameSede: 'Fénix', direction: 'Av. Nicolás de Piérola #1014' }, { nameSede: 'Sudameris', direction: 'Jr. Carabaya #933' }],
    };
  },
  computed: {

    mapCercadoDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>

<style scoped>
.v-dialog__content {
  overflow-x: hidden;
}

.dialog-content-container {
  width: 100%;
}
</style>
